import React, { Fragment, useState, useCallback } from 'react';
import moment from 'moment';
import * as GoogleAnalytics from '../../lib/google-analytics';
import LocationService from './LocationService';
import { logDataApi } from '../../lib/logDataApi';

const InitialBooking = ({
  bookingItem,
  listBooking,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
  history,
}) => {
  const [ isOpenModalLocationService, setIsOpenModalLocationService ] = useState(false);

  const openModalLocationService = useCallback(() => {
    if(bookingItem.reservationNo) logDataApi({rsvnNo: bookingItem.reservationNo, progress: '2', ref: 'Y'}); //Log 체크인 확인

    setIsOpenModalLocationService(true);
  }, [bookingItem.reservationNo]);

  const closeModalLocationService = useCallback(() => {
    setIsOpenModalLocationService(false);
  }, []);

  const validateCheckIn = (status, checkinDate, checkinTime, checkoutDate) => {
    if (status !== 'RR') return;
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_request',
    });
    const availableCheckinDate = `${checkinDate}T${checkinTime || 1500}`;
    const availableCheckoutDate = `${checkoutDate}T1100`;
    if (moment(availableCheckoutDate) < moment()) {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage('만료된 예약 입니다.');
      openModalError();
    }
    else if (moment(availableCheckinDate) < moment()) openModalLocationService();
    else {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage(`체크인은 ${moment(availableCheckinDate).format('A') === 'AM' ? '오전':'오후'} ${moment(availableCheckinDate).format('h')}시 이후 부터 가능 합니다.`);
      openModalError();
    }
  };

  const periodFormatter = (arrvDate, deptDate) => {
    return `${moment(arrvDate).format('YYYY.MM.DD')} ~ ${moment(deptDate).format('YYYY.MM.DD')}`
  };

  return (
    <Fragment>
      <span className='initial-booking-welcome-message'>
        편하고 안전한<br/>
        체크인 서비스로<br/>
        여행을 시작하세요
      </span>
      {bookingItem && bookingItem.reservationNo ? (
        <Fragment>
          <div className='initial-booking-card-container'>
            <div className='hotel-img-container'></div>
            <span className='booking-period'>
              {periodFormatter(bookingItem.arrvDate, bookingItem.deptDate)}
            </span>
            <span className='hotel-name'>
              {bookingItem.propertyName}
            </span>
            <div className='user-info-container'>
              <img className='user-icon' src='../../assets/images/profile.png' alt='' />
              <span className='user-name'>
                {bookingItem.reservationName}
              </span>
              <span className='booking-number'>
                {bookingItem.reservationNo}
              </span>
            </div>
            <div
              className={`check-in-button ${bookingItem.status !== 'RR' ? 'disabled':''}`}
              onClick={() => validateCheckIn(bookingItem.status, bookingItem.arrvDate, bookingItem.arrvPlanTime, bookingItem.deptDate)}
            >
              <span className='check-in-button-text'>
                체크인 하기
              </span>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className='initial-booking-card-container'>
          <div className='hotel-img-container'></div>
          <span className='no-booking'>예약을 찾을 수 없습니다.</span>
        </div>
      )}
      <LocationService
        isOpen={isOpenModalLocationService}
        onClose={closeModalLocationService}
        history={history}
      />
    </Fragment>
  );
};

export default InitialBooking;